.wrapper {
    border-radius: 0.5rem;
    background-color: var(--background);
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.2), 0 0.125rem 0.1875rem 0.06rem rgba(0, 0, 0, 0.24);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: max-content;
    padding: 2rem;
    transform: translate(-50%, -50%);
    z-index: 101;
    color: rgb(35, 35, 35);
}

.wrapper > div:nth-child(1) > div {
    font-size: 2rem;
    color: white;
    background-color: var(--primary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.2rem 0 0 0.2rem;
}

.wrapper > div:nth-child(1) > p {
    color: var(--primary);
    font-size: 1.2rem;
    text-align: center;
}

.wrapper > div:nth-child(2) {
    margin-top: 2rem;
    text-align: center;
}

.wrapper > div:nth-child(2) > button {
    border: none;
    border-radius: 0.2rem;
    background-color: var(--blue);
    padding: 0.4rem 1.2rem;
    color: white;
    cursor: pointer;
}

.wrapper > div:nth-child(2) > button:hover {
    background-color: rgb(69, 69, 153);
}
