.wrapper {
    margin-top: 1rem;
}

.wrapper > div:nth-child(1) {
    border-bottom: 1px var(--light-grey) solid;
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 0 1rem 0;
}

.wrapper > div:nth-child(1) > p {
    /* border: 1px red solid; */
    font-size: 0.9rem;
}

.mydocs {
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mydocs > div {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.mydocs > div > div:nth-child(1) {
    color: var(--blue);
    font-weight: 500;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.mydocs > div > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    color: lightgrey;
}

/* .mydocs > div > div:nth-child(1) > div {
    color: var(--primary);
    font-size: 0.8rem;
    font-weight: 500;
} */

input {
    border: 1px lightgrey solid;
    border-radius: 0.2rem;
    padding: 0.5rem;
    width: 10rem;
}

/* .wrapper > div:nth-child(3) {
    margin-top: 2rem;
    display: none;
    flex-direction: row;
    gap: 0;
    justify-content: center;
} */

@media (max-width: 430px) {
    .wrapper > div:nth-child(1) {
        border-bottom: 1px var(--light-grey) solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 0.2rem 0;
    }

    .wrapper > div:nth-child(1) > p:nth-child(1) {
        color: var(--grey);
        font-weight: 500;
    }

    .wrapper > div:nth-child(1) > div:nth-child(2) {
        display: none;
    }

    .wrapper > div:nth-child(2) {
        /* border: 1px red solid; */
        margin: 2rem 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
    }

    /* .wrapper > div:nth-child(3) {
        display: flex;
    } */
}
