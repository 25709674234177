.innerBody > p {
    color: var(--primary);
    font-weight: 500;
    font-size: 1.2rem;
}

.innerBody > div:nth-child(2) {
    margin-top: 1rem;
}

.innerBody > div:nth-child(2) > div:nth-child(1) > button {
    border: 1px var(--light-grey) solid;
    border-radius: 0.2rem;
    padding: 0.2rem 1rem;
    background-color: var(--background);
    color: gray;
    font-size: 0.8rem;
    margin-right: 0.1rem;
    cursor: pointer;
}

.innerBody > div:nth-child(2) > div:nth-child(1) > button:hover {
    /* border: 1px rgb(173, 173, 215) solid; */
    border: 1px rgb(157, 157, 157) solid;
    color: rgb(93, 93, 93);
    /* color: var(--blue); */
}

.innerBody > div:nth-child(2) > div:nth-child(1) > .tab,
.innerBody > div:nth-child(2) > div:nth-child(1) > .tab:hover {
    /* background-color: var(--secondary); */
    background-color: var(--blue);
    border: 1px var(--secondary) solid;
    /* color: var(--text); */
    color: var(--secondary);
}

/* .innerBody > div:nth-child(2) > div:nth-child(2) {
    border: 1px red solid;
} */

@media (max-width: 430px) {
    .innerBody > div:nth-child(2) > div:nth-child(1) {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }
    .innerBody > div:nth-child(2) > div:nth-child(1) > button {
        width: 100%;
        padding: 0.5rem 1rem;
    }
}
