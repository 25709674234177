.wrapper > div:nth-child(1) {
    color: var(--primary);
    font-weight: 500;
    font-size: 1.2rem;
}

.wrapper > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    color: var(--secondary);
    height: 70vh;
}
