.btnUpload {
    border: 1px var(--primary) solid;
    border-radius: 0.2rem;
    padding: 0.2rem 0.5rem;
    background-color: var(--primary);
    margin-left: 0.5rem;
    color: var(--background);
    width: 5rem;
    cursor: pointer;
}

.btnUpload:hover {
    background-color: var(--button-hover);
}
