.tableWrapper {
    border: none;
    max-width: 100%;
    /* background-color: white; */
}

.table {
    border-collapse: separate;
    border-spacing: 0 1px;
    display: table;
    width: 100%;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 0 2px 3px 1px rgba(236, 236, 236, 0.719);
}

.table tr {
    box-shadow: 0 2px 3px var(--light);
}

.table tr:nth-child(5) {
    border-radius: 0.5rem 0.5rem 1rem 1rem;
}

.table tr th {
    /* border: 1px red solid; */
    background-color: var(--blue);
    /* box-shadow: 0px 0px 0px 1px var(--light); */
    font-weight: 500;
    font-size: 0.9rem;
    padding: 0.7rem;
    /* border-radius: 4px; */
    /* text-align: left; */
    color: var(--white);
}

.table tr th:nth-child(1) {
    border-radius: 1rem 0 0 0;
}

.table tr th:nth-child(10) {
    border-radius: 0 1rem 0 0;
}

.table tr th:nth-child(1) {
    border-radius: 1rem 0 0 0;
}

.table tr td:nth-child(5) {
    /* border: 1px red solid; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10rem;
    /* text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-width: 10rem; */
}

.table tr td {
    /* border: 1px black solid; */
    text-align: center;
    font-size: 0.8rem;
    color: var(--dark);
    padding: 0.7rem;
    background-color: var(--white);
    /* border-radius: 4px; */
}

.table tr:nth-child(5) td:nth-child(1) {
    border-radius: 0 0 0 1rem;
}

.table tr:nth-child(5) td:nth-child(10) {
    border-radius: 0 0 1rem 0;
}
