.wrapper {
    margin-top: 1rem;
}

.wrapper > div:nth-child(1) {
    border-bottom: 1px var(--light-grey) solid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0 1rem 0;
}

.wrapper > div:nth-child(1) > p {
    font-size: 0.9rem;
}

@media (max-width: 430px) {
    .wrapper > div:nth-child(1) {
        justify-content: center;
        padding: 1rem 0 0.2rem 0;
    }

    .wrapper > div:nth-child(1) > p:nth-child(1) {
        color: var(--grey);
        font-weight: 500;
    }
}
