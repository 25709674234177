.wrapper {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: var(--grey);
    margin: 0.4rem 0;
}

.wrapper > label {
    width: 15rem;
    font-size: 0.8rem;
    color: var(--blue);
}

.wrapper > input {
    border: 1px var(--light-grey) solid;
    outline: none;
    border-radius: 0.3rem;
    padding: 0.4rem 0 0.4rem 0.5rem;
    background-color: var(--background);
    width: 33rem;
}

.wrapper > input:hover,
.wrapper > textarea:hover {
    border: 1px rgb(107, 107, 107) solid;
}

.wrapper > input:disabled {
    border: 1px transparent solid;
    padding: 0 0 0 0.7rem;
}

.wrapper > textarea {
    border: 1px var(--light-grey) solid;
    border-radius: 0.3rem;
    outline: none;
    padding: 0.4rem 0 0.4rem 0.5rem;
    background-color: var(--background);
    width: 33rem;
    min-height: 7rem;
    max-width: 100%;
}

.wrapper > textarea:disabled {
    border: 1px transparent solid;
    padding: 0 0 0 0.7rem;
    width: 50rem;
}

.wrapper > .inputCurrency {
    border: 1px var(--light-grey) solid;
    border-radius: 0.3rem;
    padding: 0rem 0 0rem 0.5rem;
    background-color: var(--background);
    width: 12rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.wrapper > .inputCurrency > div {
    background-color: lightgray;
    border-radius: 0.2rem;
    padding: 0.1rem 0.4rem;
    font-size: 0.8rem;
    margin: auto;
}

.wrapper > .inputCurrency > input {
    width: 100%;
    border: none;
    outline: none;
}

.inputTel {
    width: 33rem;
    position: relative;
}

.inputTel > input {
    position: absolute;
    top: 1rem;
    left: 2rem;
    opacity: 1;
    height: 0;
    z-index: -1;
}

.selectField {
    font-size: 0.8rem;
    width: 33rem;
    position: relative;
}
.selectField > input {
    position: absolute;
    bottom: 0rem;
    left: 2rem;
    opacity: 1;
    height: 0rem;
    z-index: -1;
}

.inputFile {
    /* border: 1px red solid; */
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
}

.inputFile > .inputClick {
    background-color: var(--secondary);
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.inputFile > .inputClick > div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.inputFile > .inputClick > input {
    display: none;
}

.file {
    width: 10rem;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.file > img {
    max-width: 100%;
    max-height: 100%;
}

.pdf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pdfIcon {
    font-size: 3rem;
}

.cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    font-size: 0.9rem;
    color: var(--button-cancel);
    cursor: pointer;
}

.iconCancel {
    font-size: 1.2rem;
}

.alertImage {
    font-size: 0.9rem;
    color: var(--button-cancel);
}

@media (max-width: 430px) {
    .wrapper {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        color: var(--grey);
        margin: 0.5rem 0;
    }

    .wrapper > label {
        width: 100%;
    }

    .wrapper > input {
        border: 1px var(--light-grey) solid;
        outline: none;
        width: 100%;
    }

    .wrapper > input:disabled {
        border: 1px transparent solid;
        padding: 0 0 0 0;
        font-weight: 500;
    }

    .wrapper > input:focus {
        border-bottom: 1px var(--primary) solid;
    }

    .wrapper > textarea {
        border: 1px var(--light-grey) solid;
        border-radius: 0.3rem;
        outline: none;
        padding: 0.3rem 0 0.3rem 0.5rem;
        background-color: var(--background);
        width: 100%;
        min-height: 7rem;
        max-width: 100%;
    }

    .wrapper > textarea:disabled {
        border: 1px transparent solid;
        padding: 0 0 0 0;
        width: 100%;
        font-weight: 500;
    }

    .wrapper > textarea:focus {
        border-bottom: 1px var(--primary) solid;
        outline: 0;
    }

    .wrapper > .inputCurrency:hover {
        border-bottom: 1px var(--primary) solid;
    }

    .selectField,
    .inputTel {
        width: 100%;
    }
}
