.wrapper {
    /* border: 1px red solid; */
    border-radius: 0.5rem;
    background-color: var(--background);
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.2), 0 0.125rem 0.1875rem 0.06rem rgba(0, 0, 0, 0.24);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: max-content;
    padding: 1rem;
    transform: translate(-50%, -50%);
    z-index: 101;
    color: rgb(35, 35, 35);
}

.wrapper > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.wrapper > div:nth-child(1) > p {
    color: var(--primary);
    font-weight: 500;
    font-size: 1.2rem;
}

.iconClose {
    font-size: 1.2rem;
    color: rgb(134, 0, 0);
    cursor: pointer;
}

.iconClose:hover {
    color: rgb(209, 0, 0);
}

.wrapper > div:nth-child(2) {
    /* border-top: 0.2rem var(--blue) solid; */
    overflow-y: auto;
    height: 80vh;
}

.wrapper > div:nth-child(2) > .close {
    border-top: 0.2rem var(--primary) solid;
    background-color: rgba(255, 239, 230, 0.845);
    border-radius: 0.1rem;
    margin: 2rem 1rem 2rem 0;
    cursor: pointer;
    height: 2.7rem;
    overflow: hidden;
    cursor: pointer;
}

.wrapper > div:nth-child(2) > .open {
    border-top: 0.2rem var(--primary) solid;
    background-color: rgb(252, 252, 252);
    border-radius: 0.1rem;
    padding-left: 0.5rem;
    margin: 2rem 1rem 2rem 0;
    cursor: pointer;
}

.wrapper > div:nth-child(2) > .close:hover {
    background-color: rgba(255, 150, 89, 0.845);
}

.wrapper > div:nth-child(2) > .close > div > div {
    height: 0;
    overflow: hidden;
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 0;
}

.open > div > .title {
    border-bottom: 0.1rem lightgrey solid;
}

.close > div > .title {
    padding-left: 0.5rem;
}

.imgValidation {
    font-size: 0.8rem;
    margin-bottom: 2rem;
    text-align: center;
}

.uploadImage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
}

.subImages {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.submitButtonDiv {
    padding: 3rem 0 2rem 0;
    text-align: center;
}

.successMsg {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
    cursor: default;
}

.btnAdd {
    /* border: 1px var(--blue) solid; */
    border: none;
    background-color: transparent;
    border-radius: 0.2rem;
    /* padding: 0.2rem 0.6rem; */
    padding: 0;
    color: var(--primary);
    cursor: pointer;
    margin: 0.5rem 0;
}

.btnAdd:hover {
    color: orangered;
}

.btnAdd:disabled {
    display: none;
    color: #929292;
    cursor: default;
}

.inputMessage {
    position: relative;
}

.inputMessage > input {
    position: absolute;
    top: 2rem;
    opacity: 0;
    height: 0;
}

.currentCategory {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
    color: var(--grey);
}

.newCategory {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.category > div > div {
    font-size: 0.8rem;
}

/* ================== */

@media (max-width: 430px) {
    .wrapper {
        width: 30rem;
    }

    .wrapper > div:nth-child(2) {
        width: 30rem;
    }

    /* .wrapper > form > div:nth-child(2) {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .wrapper > form > div:nth-child(2) > div {
        width: 100%;
    }

    .pricingField {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .pricingField > div:nth-child(1) {
        width: 100%;
    }

    .pricingField > div:nth-child(1) > div {
        width: 100%;
    }

    .pricingField > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 0;
        padding-right: 0;
        gap: 0rem;
        color: var(--grey);
        width: 100%;
    }

    .pricingField > div:nth-child(2) > p {
        width: 30%;
        color: var(--blue);
    }

    .pricingField > div:nth-child(2) > div {
        width: 70%;
        margin-right: 0;
    }

    .wrapper > form > div:nth-child(3) {
        padding: 1rem 0.5rem 0 0;
    }

    .wrapper > form > div:nth-child(3) > div > div > div {
        margin-top: 1rem;
    }

    .wrapper > form > div:nth-child(3)::-webkit-scrollbar {
        width: 0.2rem;
        height: 0.2rem;
    }

    .wrapper > form > div:nth-child(3) > .uploadImage > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
        gap: 5rem;
    }

    .wrapper > form > div:nth-child(3) > .uploadImage > div > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    } */

    .open > div > div {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: column;
    }

    .open > div > div > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
