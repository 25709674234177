/* .wrapper > input[type='file'] {
} */

.wrapperFile {
    border-bottom: 1px var(--light-grey) solid;
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(74, 74, 74);
    margin: 0.2rem 0;
}

.wrapperFile > label {
    width: 15rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--blue);
}

.logo {
    border: 1px var(--light-grey) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    overflow: hidden;
    padding: 0.4rem;
}

.banner {
    border: 1px var(--light-grey) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.2rem;
    width: 15rem;
    height: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    overflow: hidden;
}

.logo > img,
.banner > img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
}

.wrapperFileImage {
    display: flex;
    flex-direction: row;
}

.wrapperImage {
    border: none;
    border-radius: 0.5rem;
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
    min-width: 10rem;
    padding: 1rem;
    cursor: pointer;
}

.wrapperImage > .iconStyle {
    font-size: 1.2rem;
}

.wrapperImage > p:nth-child(2) {
    font-weight: bold;
    font-size: 0.7rem;
}

.wrapperImage > p:nth-child(3) {
    font-size: 0.5rem;
}

.wrapperImage > input {
    display: none;
}

@media (max-width: 430px) {
    .wrapperFile {
        border-bottom: 1px var(--light-grey) solid;
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
        color: rgb(74, 74, 74);
        margin: 0.2rem 0;
    }

    .wrapperFileImage {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .wrapperImage {
        min-width: max-content;
    }

    .logo,
    .banner {
        margin-left: 2rem;
        margin-right: 1rem;
    }

    .banner {
        width: 100%;
    }
}
