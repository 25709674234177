.wrapper > form > div:nth-child(1) {
    color: var(--primary);
    font-weight: 500;
    font-size: 1.2rem;
}

.rfq > div > p {
    border-left: 3px var(--blue) solid;
    color: rgb(53, 53, 94);
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
    padding: 0 0 0 0.4rem;
    gap: 3rem;
}

.imageAlert {
    color: var(--button-cancel);
    display: flex;
    justify-content: center;
    align-items: center;
}

.rfq {
    border-top: 0.4rem var(--secondary) solid;
    border-bottom: 0.4rem var(--secondary) solid;
    /* border: 0.4rem rgb(243, 237, 230) solid; */
    /* border-radius: 0.5rem; */
    /* padding: 1rem 1rem 4rem 1rem; */
    margin: 1rem 0;
    padding: 1rem 0 0 0;
    height: 55vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #c5c5c5 #f6f6f6;
}

.rfq::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
}

.rfq::-webkit-scrollbar-track {
    background: #f6f6f6;
}

.rfq::-webkit-scrollbar-thumb {
    background-color: #929292;
    border-radius: 20px;
    border: none;
}

.rfq > div {
    margin-bottom: 3rem;
    /* background-color: var(--secondary);
    border-radius: 0.5rem;
    padding: 1rem;
    margin-right: 0.5rem; */
}

.pricingField {
    /* border: 1px red solid; */
    width: 48rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.pricingField > div:nth-child(2) {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: var(--grey);
}

.pricingField > div:nth-child(2) > * {
    font-size: 0.8rem;
}

.pricingField > div:nth-child(2) > div:nth-child(2) {
    width: 11rem;
}

.discountPrice {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
}

.btnAdd {
    /* border: 1px var(--blue) solid; */
    border: none;
    background-color: transparent;
    border-radius: 0.2rem;
    /* padding: 0.2rem 0.6rem; */
    padding: 0;
    color: var(--primary);
    cursor: pointer;
    margin: 0.5rem 0;
}

.btnAdd:hover {
    color: orangered;
}

.btnAdd:disabled {
    display: none;
    color: #929292;
    cursor: default;
}

.inputMessage {
    position: relative;
}

.inputMessage > input {
    position: absolute;
    top: 2rem;
    opacity: 0;
    height: 0;
}

/* .rfq > .uploadImage {
    border: 1px red solid;
} */

.rfq > .uploadImage > div {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    gap: 5rem;
}

.rfq > .uploadImage > div > div:nth-child(2) {
    /* border: 1px blue solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.wrapper > form > div:nth-child(3) {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

/* ================== */

@media (max-width: 430px) {
    .wrapper > form > div:nth-child(2) {
        /* border: 1px red solid; */
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .wrapper > form > div:nth-child(2) > div {
        /* border: 1px red solid; */
        width: 100%;
    }

    .pricingField {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .pricingField > div:nth-child(1) {
        /* border: 1px red solid; */
        width: 100%;
    }

    .pricingField > div:nth-child(1) > div {
        /* border: 1px red solid; */
        width: 100%;
    }

    .pricingField > div:nth-child(2) {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 0;
        padding-right: 0;
        gap: 0rem;
        color: var(--grey);
        width: 100%;
    }

    .pricingField > div:nth-child(2) > p {
        /* border: 1px red solid; */
        width: 30%;
        color: var(--blue);
    }

    .pricingField > div:nth-child(2) > div {
        /* border: 1px red solid; */
        width: 70%;
        margin-right: 0;
    }

    .pricingField > div:nth-child(2) > div:nth-child(2) {
        width: 80%;
    }

    .rfq {
        padding: 1rem 0.5rem 0 0;
    }

    .rfq > div > div > div {
        margin-top: 1rem;
    }

    .rfq::-webkit-scrollbar {
        width: 0.2rem;
        height: 0.2rem;
    }

    /* .rfq > .uploadImage {
        border: 1px red solid;
        display: flex;
        flex-direction: column;
    } */

    .rfq > .uploadImage > div {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
        gap: 5rem;
    }

    .rfq > .uploadImage > div > div:nth-child(2) {
        /* border: 1px blue solid; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
}
