.wrapper {
    background-image: url('../../assets/login/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.wrapper > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    width: 100%;
    height: 100%;
}

.wrapper > div > form {
    background: rgba(221, 221, 221, 0.474);
    border: 1px solid rgba(255, 255, 255, 0.21);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 0.3rem;
    padding: 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.wrapper > div > form > div:nth-child(1) {
    width: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
}

.wrapper > div > form > div:nth-child(1) > img {
    width: 100%;
}

.wrapper > div > form > div:nth-child(1) > p {
    margin: 0;
    font-size: 1rem;
    color: rgb(29, 76, 114);
    font-weight: bold;
}

.alertMessage {
    color: var(--button-cancel);
    font-size: 0.8rem;
}

.wrapper > div > form > .inputValue {
    width: 26rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

/* .wrapper > div > form > .inputValue > p {
    width: 6rem;
    font-size: 1rem;
    color: var(--blue);
} */

.wrapper > div > form > .inputValue > input {
    border: none;
    border-bottom: 1px var(--blue) solid;
    border-radius: 0.1rem;
    outline: none;
    color: var(--blue);
    padding: 0.3rem 0.8rem;
    width: 20rem;
    background-color: rgba(255, 255, 255, 0.68);
}

.wrapper > div > form > .inputValue > input:hover {
    border: none;
    border-bottom: 1px var(--blue) solid;
}

.wrapper > div > form > .inputValue > input:focus {
    border: none;
    border-bottom: 1px var(--primary) solid;
}

.wrapper > div > form > button {
    border: none;
    border-radius: 0.2rem;
    background-color: rgb(67, 67, 127);
    padding: 0.3rem 1rem;
    color: var(--background);
    font-size: 1rem;
    cursor: pointer;
}

.wrapper > div > form > button:hover {
    background-color: var(--blue);
}

.keepLogin {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.3rem;
    color: white;
    font-weight: 500;
}

.keepLogin input {
    width: fit-content;
}
