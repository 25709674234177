.sidebar {
    background-color: var(--background);
    height: 100vh;
    position: sticky;
    top: 0;
    z-index: 1;
}

.logoDiv {
    /* border: 1px red solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4rem;
}

.logoDiv > img {
    /* border: 1px red solid; */
    display: inline-block;
    vertical-align: middle;
    max-width: 70%;
    max-height: 100%;
}

.sidebar > div:nth-child(2) {
    margin-top: 1rem;
    padding-right: 1rem;
}

.sidebar > div:nth-child(2) > li {
    list-style: none;
    margin: 0.5rem 0;
}

.sidebar > div:nth-child(2) > li a {
    text-decoration: none;
}

.sidebar > div:nth-child(2) > li .active {
    background-color: var(--primary) !important;
    color: white;
    padding: 0.7rem 1rem 0.5rem 2rem;
    border-radius: 0 0.5rem 0.5rem 0;
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
}

* {
    margin: 0;
}

.sidebar > div:nth-child(2) > li .inactive {
    color: var(--text-light);
    padding: 0.7rem 1rem 0.5rem 2rem;
    border-radius: 0 0.9rem 0.9rem 0;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
}

.sidebar > div:nth-child(2) > li .active > div:nth-child(1),
.sidebar > div:nth-child(2) > li .inactive > div:nth-child(1) {
    /* border: 1px red solid; */
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar > div:nth-child(2) > li .active > div:nth-child(1) > *,
.sidebar > div:nth-child(2) > li .inactive > div:nth-child(1) > * {
    font-size: 1rem;
}

.sidebar > div:nth-child(2) > li .inactive:hover {
    /* background-color: var(--secondary) !important; */
    color: var(--blue);
}

.sidebar > div:nth-child(2) > li .btnActive {
    border: none;
    background-color: var(--primary) !important;
    color: white;
    padding: 0.7rem 1rem 0.5rem 2rem;
    border-radius: 0 0.9rem 0.9rem 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: 0.8rem;
    width: 100%;
}

.sidebar > div:nth-child(2) > li .btnActive > div:nth-child(1),
.sidebar > div:nth-child(2) > li .btnInactive > div:nth-child(1) {
    /* border: 1px red solid; */
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar > div:nth-child(2) > li .btnActive > div:nth-child(1) > *,
.sidebar > div:nth-child(2) > li .btnInactive > div:nth-child(1) > * {
    font-size: 1rem;
}

.sidebar > div:nth-child(2) > li .btnActive > div:nth-child(2),
.sidebar > div:nth-child(2) > li .btnInactive > div:nth-child(2) {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.sidebar > div:nth-child(2) > li .btnInactive {
    border: none;
    background-color: var(--background);
    color: var(--text-light);
    padding: 0.7rem 1rem 0.5rem 2rem;
    border-radius: 0 0.9rem 0.9rem 0;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: 0.8rem;
    width: 100%;
}

.sidebar > div:nth-child(2) > li .btnInactive:hover {
    /* background-color: var(--secondary) !important; */
    color: var(--blue);
}

.dropDown {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: column;
    padding: 0rem 0 0 30%;
    font-size: 0.8rem;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}

.dropDown.open {
    /* display: flex;
    flex-direction: column; */
    /* border-bottom: 1px var(--primary) solid; */
    /* border-radius: 0 0 0.5rem 0;
    background-color: var(--secondary); */
    height: 4rem;
}

.dropDown a {
    text-decoration: none;
    color: var(--text-light);
    padding-top: 0.5rem;
}

.dropDown a:hover {
    color: var(--blue);
}

.logout {
    margin-top: 2rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logout > button {
    border: none;
    background-color: var(--background);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.logout > button > div:nth-child(1) {
    /* border: 1px red solid; */
    display: flex;
    justify-content: center;
    background-color: var(--primary);
    padding: 0.5rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.logout > button > div:nth-child(2) {
    color: var(--text-light);
    font-size: 1rem;
}

.logout > button > div:nth-child(2):hover {
    color: var(--button-cancel);
    font-size: 1rem;
}

.iconDropDown {
    /* border: 1px red solid; */
    color: black;
    background-color: transparent;
    font-size: 1.3rem;
    right: 1;
}

.iconStyle {
    /* border: 1px red solid; */
    color: var(--background);
    background-color: var(--primary);
    font-size: 1.5rem;
}

.leftArrow,
.rightArrow {
    display: none;
}

@media (max-width: 430px) {
    .sidebar {
        /* border: 1px red solid; */
        background-color: var(--background);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 4rem;
        position: sticky;
        top: 4rem;
        z-index: 1;
    }

    .logoDiv {
        /* border: 1px green solid; */
        display: none;
    }

    .sidebar > div:nth-child(2) {
        /* border: 1px blue solid; */
        margin-top: 0rem;
        padding: 0 1.5rem 0 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #c5c5c5 #f6f6f6;
    }

    .sidebar > div:nth-child(2)::-webkit-scrollbar {
        width: 0.1rem;
        height: 0.1rem;
    }

    .sidebar > div:nth-child(2)::-webkit-scrollbar-track {
        /* background: #f6f6f6; */
        background-color: transparent;
    }

    .sidebar > div:nth-child(2)::-webkit-scrollbar-thumb {
        /* background-color: #929292; */
        background-color: transparent;
        border-radius: 20px;
        border: none;
    }

    .sidebar > div:nth-child(2) > li .active {
        background-color: var(--primary) !important;
        color: white;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: row;
        font-size: 0.8rem;
    }

    .sidebar > div:nth-child(2) > li .active,
    .sidebar > div:nth-child(2) > li .inactive {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 12rem;
        padding: 0.7rem 0rem 0.5rem 0rem;
        border-radius: 0.5rem;
    }

    .sidebar > div:nth-child(2) > li .active > div:nth-child(2),
    .sidebar > div:nth-child(2) > li .inactive > div:nth-child(2) {
        /* border: 1px red solid; */
        width: fit-content;
    }

    .sidebar > div:nth-child(2) > li .btnActive {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 12rem;
        padding: 0.7rem 0rem 0.5rem 0rem;
        border-radius: 0.5rem;
    }

    .sidebar > div:nth-child(2) > li .btnActive > div:nth-child(2) {
        /* border: 1px red solid; */
        width: fit-content;
    }

    .iconDropDown {
        /* border: 1px red solid; */
        display: none;
    }

    .productText::after {
        content: '˅';
        margin-left: 0.5rem;
    }

    .dropDown {
        background-color: var(--background);
        position: absolute;
        margin-top: 1rem;
        border-radius: 0.1rem;
        display: flex;
        flex-direction: column;
        width: 12rem;
        padding: 0rem 0rem 0rem 0rem;
        font-size: 0.8rem;
        height: 0;
        overflow: hidden;
        transition: height 0s ease-in-out;
    }

    .dropDown.open {
        border: 1px lightgray solid;
        padding: 0rem 0.7rem 0.5rem 0.5rem;
        height: 4rem;
    }

    .logout {
        margin-top: 0rem;
        padding-left: 0rem;
    }

    .logout > button > div:nth-child(2) {
        color: var(--primary);
        font-size: 1rem;
        display: none;
    }

    .leftArrow,
    .rightArrow {
        /* border-radius: 50%; */
        background-color: #d0d0d0d4;
        color: #454545;
        border-radius: 0.1rem;
        width: 1rem;
        height: 1.7rem;
        padding: 0.1rem 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        /* top: 0; */
    }

    .leftArrow {
        left: 0.2rem;
        display: none;
    }
    .rightArrow {
        right: 0.2rem;
    }
}
