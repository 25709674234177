@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&family=Roboto:wght@300;400;500&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

* {
    box-sizing: border-box;
}

:root {
    --background: rgb(255, 255, 255);
    --primary: #fb7b30;
    --primary-light: #ffceb2;
    --button-hover: rgb(255, 101, 24);
    --button-cancel: rgb(185, 0, 0);
    --secondary: #fff6f1;
    --text: rgb(0, 0, 0);
    --text-light: #828282;
    --blue: rgb(57, 57, 120);
    --light-grey: #d1d1d1;
    --grey: rgb(74, 74, 74);
    --dark-grey: rgb(64, 64, 64);
    --box-shadow: 0.03rem 0.01rem 0.5rem 0.01rem lightgray;
}

body {
    padding: 0;
    margin: 0;
    background-color: var(--secondary);
}

html {
    font-size: 100%;
}

@media (max-width: 1130px) {
    html {
        font-size: 80%;
    }
}

@media (max-width: 770px) {
    html {
        font-size: 50%;
    }
}

@media (max-width: 430px) {
    html {
        font-size: 80%;
    }
}

@media (max-width: 380px) {
    html {
        font-size: 75%;
    }
}

@media (max-width: 320px) {
    html {
        font-size: 60%;
    }
}
