.wrapper {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--grey);
    margin: 0.2rem 0;
    gap: 5rem;
}

.wrapper > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wrapper > div > label {
    font-size: 0.8rem;
    color: var(--blue);
}

.wrapper > div:nth-child(1) > label {
    width: 15rem;
}

.wrapper > div:nth-child(2) > label {
    width: fit-content;
    margin-right: 1rem;
}

.wrapper > div:nth-child(3) > label {
    width: fit-content;
    margin-right: 1rem;
}

.wrapper > div > .inputCurrency {
    border: 1px var(--light-grey) solid;
    border-radius: 0.3rem;
    padding: 0.3rem 0 0.3rem 0.5rem;
    background-color: var(--background);
    width: 10rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.wrapper > div > .inputCurrency > div {
    background-color: lightgray;
    border-radius: 0.2rem;
    padding: 0.1rem 0.4rem;
    font-size: 0.8rem;
    margin: auto;
}

.wrapper > div > .inputCurrency > input {
    width: 100%;
    border: none;
    outline: none;
}

.priceText {
    font-weight: bold;
}

@media (max-width: 430px) {
    .wrapper {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--grey);
        margin: 0.2rem 0;
        gap: 1rem;
    }

    .wrapper > div {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .wrapper > div:nth-child(1) > label {
        width: 100%;
    }

    .wrapper > div:nth-child(2) > label {
        width: 100%;
        margin-right: 0rem;
    }

    .wrapper > div:nth-child(3) > label {
        width: 100%;
        margin-right: 0rem;
    }

    .wrapper > div > .inputCurrency {
        /* border: 1px blue solid; */
        width: 100%;
    }

    .wrapper > div > .inputCurrency:hover {
        border-bottom: 1px var(--primary) solid;
    }
}
