.wrapper {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(74, 74, 74);
    margin: 0.2rem 0;
}

/* .wrapper > label {
    width: 15rem;
    font-size: 0.8rem;
} */

.wrapper > input:nth-child(1) {
    border: 1px var(--light-grey) solid;
    outline: none;
    border-radius: 0.3rem;
    color: rgb(74, 74, 74);
    padding: 0.3rem 0 0.3rem 0.5rem;
    background-color: var(--background);
    width: 13rem;
    margin-right: 2rem;
    font-size: 0.8rem;
}

.wrapper > input:nth-child(2) {
    border: 1px var(--light-grey) solid;
    outline: none;
    border-radius: 0.3rem;
    color: rgb(74, 74, 74);
    padding: 0.3rem 0 0.3rem 0.5rem;
    background-color: var(--background);
    width: 30rem;
    margin-right: 1rem;
    font-size: 0.8rem;
}

.wrapper > input:disabled {
    border: 1px transparent solid;
    padding: 0 0 0 0.7rem;
}

.btnRemove {
    border: none;
    background-color: transparent;
    border-radius: 0.2rem;
    /* padding: 0.2rem 0.6rem; */
    font-size: 1rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: rgb(140, 0, 0);
}

.btnRemove:hover {
    color: red;
}

@media (max-width: 430px) {
    .wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.5rem;
    }
    .wrapper > input:nth-child(1),
    .wrapper > input:nth-child(2) {
        width: 100%;
    }
}
