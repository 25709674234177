.wrapper {
    /* border: 1px red solid; */
    background-color: var(--secondary);
    margin: 1rem;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.wrapper > div:nth-child(1) {
    /* border: 1px red solid; */
    border-radius: 0.2rem;
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    width: 80%;
}

.wrapper > div:nth-child(1) > div:nth-child(1) {
    /* border: 1px red solid; */
    background-color: var(--background);
    box-shadow: 0rem 0rem 0.5rem 0rem rgb(249, 244, 244);
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
}

.wrapper > div:nth-child(1) > div:nth-child(1) > p:nth-child(1) {
    color: var(--primary);
    font-weight: 500;
    font-size: 1.2rem;
}

.wrapper > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
    /* border: 1px red solid; */
    margin: 1rem 0;
}

.wrapper > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > p:nth-child(1) {
    /* border: 1px green solid; */
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.wrapper > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) {
    /* border: 1px blue solid; */
    background-color: var(--secondary);
    border-radius: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.wrapper > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > input {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0.4rem 0.2rem 0.4rem 0.5rem;
    font-size: 0.8rem;
    width: 100%;
}

.wrapper > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > div > label {
    font-size: 0.8rem;
}

/* .wrapper > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
    border-bottom: 1px var(--text-light) solid;
    padding: 1rem 0;
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    gap: 5rem;
} */

.wrapper > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
    /* border: 1px red solid; */
    margin: 1rem 0;
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wrapper > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
    background-color: var(--blue);
    border-radius: 0.2rem;
    padding: 0.3rem 1rem;
    color: white;
    cursor: pointer;
}

.wrapper > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(2):hover {
    background-color: rgb(72, 72, 131);
}

/* ===================================================================== */

body::-webkit-scrollbar {
    width: 1.1rem;
    height: 0.7rem;
}

body {
    scrollbar-width: thin;
    scrollbar-color: #c5c5c5 #f6f6f6;
}

body::-webkit-scrollbar-track {
    background: #ffffff;
}

body::-webkit-scrollbar-thumb {
    background-color: #616161;
    border-radius: 20px;
    border: 4px white solid;
}

/* ======================================================================= */

.wrapper > div:nth-child(1) > div:nth-child(2) > .productList {
    /* border: 1px red solid; */
    margin: 1rem 0;
    padding: 0.5rem 0;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 65vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #c5c5c5 #f6f6f6;
}

.productList::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
}

.productList::-webkit-scrollbar-track {
    background: #f6f6f6;
}

.productList::-webkit-scrollbar-thumb {
    background-color: #929292;
    border-radius: 20px;
    border: none;
}

.wrapper > div:nth-child(2) {
    width: 20%;
}

.paginationButton {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
}

.paginationButton > div:nth-child(1) {
    font-size: 0.8rem;
    color: #2d2d2d;
}

.paginationButton > button {
    /* border: 1px var(--blue) solid; */
    border: none;
    /* border-radius: 0.2rem; */
    /* padding: 0.2rem 0.6rem; */
    background-color: transparent;
    color: var(--blue);
    font-size: 0.8rem;
    cursor: pointer;
}

.paginationButton > button:hover {
    color: var(--blue);
}

.paginationButton button:disabled {
    /* border: 1px lightgrey solid; */
    /* background-color: rgb(236, 236, 236); */
    border: none;
    color: gray;
}

.wrapper > div:nth-child(2) > div:nth-child(1) {
    /* border: 1px red solid; */
    box-shadow: 0rem 0rem 0.5rem 0.1rem rgb(249, 244, 244);
    background-color: var(--background);
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
}

.wrapper > div:nth-child(2) > div:nth-child(1) > p:nth-child(1) {
    font-size: 1rem;
    color: var(--primary);
    margin-bottom: 0.5rem;
}

.wrapper > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > p {
    font-size: 0.8em;
}

.wrapper > div:nth-child(2) > div:nth-child(2) {
    background-color: var(--background);
    box-shadow: 0rem 0rem 0.5rem 0.1rem rgb(249, 244, 244);
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
}

@media (max-width: 430px) {
    .wrapper > div:nth-child(1) {
        width: 100%;
    }

    .wrapper > div:nth-child(2) {
        display: none;
    }

    .wrapper > div:nth-child(1) > div:nth-child(2) > .productList {
        /* border: 1px red solid; */
        margin: 1rem 0;
        padding: 0.5rem 0;
        font-size: 0.8rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 58vh;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #c5c5c5 #f6f6f6;
    }
}
