.wrapper {
    margin-top: 1rem;
}

.wrapper > div:nth-child(1) {
    border-bottom: 1px var(--light-grey) solid;
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 0 1rem 0;
}

.wrapper > div:nth-child(1) > p {
    /* border: 1px red solid; */
    font-size: 0.9rem;
}

.wrapper > div:nth-child(2) {
    /* border: 1px red solid; */
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper > div:nth-child(3) {
    margin-top: 2rem;
    display: none;
    flex-direction: row;
    gap: 0;
    justify-content: center;
}

.selectField {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: var(--grey);
    margin: 0.2rem 0;
}

.selectField > label {
    /* border: 1px red solid; */
    width: 15rem;
    font-size: 0.8rem;
    color: var(--blue);
}

.selectField > .selectFieldActive {
    /* border: 1px var(--light-grey) solid; */
    outline: none;
    border-radius: 0.3rem;
    padding: 0.3rem 0 0.3rem 0rem;
    background-color: var(--background);
    font-size: 0.8rem;
    width: 30rem;
}

.selectField > .selectFieldDisabled {
    outline: none;
    border-radius: 0.3rem;
    padding: 0 0 0 0.7rem;
    background-color: var(--background);
    font-size: 0.8rem;
    width: 30rem;
}

@media (max-width: 430px) {
    .wrapper > div:nth-child(1) {
        border-bottom: 1px var(--light-grey) solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 0.2rem 0;
    }

    .wrapper > div:nth-child(1) > p:nth-child(1) {
        color: var(--grey);
        font-weight: 500;
    }

    .wrapper > div:nth-child(1) > div:nth-child(2) {
        display: none;
    }

    .wrapper > div:nth-child(2) {
        /* border: 1px red solid; */
        margin: 2rem 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
    }

    .wrapper > div:nth-child(3) {
        display: flex;
    }

    .selectField {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        color: var(--grey);
        margin: 0.5rem 0;
    }

    .selectField > label {
        width: 100%;
    }

    .selectField > .selectFieldActive {
        width: 100%;
    }

    .selectField > .selectFieldDisabled {
        padding: 0.3rem 0 0 0;
        font-weight: 500;
        font-size: 1.05rem;
    }
}
