.loader {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader > div:nth-child(1) {
    font-size: 1.5rem;
    color: var(--blue);
}
