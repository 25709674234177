.navbar {
    height: 4rem;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px var(--background) solid;
    box-shadow: 0.03rem 0.01rem 0.5rem 0.01rem rgb(242, 232, 232);
    clip-path: inset(0px 0px -15px 0px);
    position: sticky;
    top: 0;
    z-index: 100;
}

.navbarLeft {
    /* border: 1px red solid; */
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 10rem;
    height: 100%;
}

.navbarLeft > img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
}

.navbarRight {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    width: 100%;
}

.switch {
    background-color: var(--secondary);
    border-radius: 2rem;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.active {
    background-color: var(--primary);
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
}

.inactive {
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    color: grey;
    font-size: 0.9rem;
    cursor: pointer;
}

.sectiontab {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.sectiontab {
    /* border: 1px red solid; */
    gap: 1rem;
}

.sectiontab > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sectiontab > .notification::after {
    content: attr(current-count);
    position: absolute;
    top: 0;
    right: 0;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: var(--primary);
    padding-top: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    border: 1px var(--background) solid;
}

/* .navbar > div:nth-child(1) > .notification > div:nth-child(1) {
    position: absolute;
    border: none;
    background-color: var(--primary);
    border-radius: 50%;
    font-size: 0.7rem;
    text-align: center;
    padding: 0.2rem;
    top: -0.5rem;
    right: -0.3rem;
    color: white;
}

.navbar > div:nth-child(1) > .notification > div:nth-child(1) > p {
    width: 100%;
} */

.profile {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    gap: 0.1rem;
}

.profile > div:nth-child(1) {
    border: 1px var(--secondary) solid;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0.2rem;
}

.profile > div:nth-child(1) > img {
    /* border: 1px red solid; */
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
}

.userIcon {
    font-size: 1.1rem;
    color: var(--grey);
}

.profile > button {
    border: none;
    background-color: var(--background);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.profile > button > .button1 {
    display: block;
}

.profile > button > .button2 {
    display: none;
}

.profile > button > div > p {
    /* border: 1px red solid; */
    font-size: 0.9rem;
    color: var(--blue);
}

.iconStyle {
    font-size: 1.5rem;
    color: var(--text-light);
}

.iconDropdown {
    padding-top: 0.2rem;
    font-size: 1.5rem;
    color: var(--blue);
}

.iconUser {
    color: var(--blue);
}

@media (max-width: 430px) {
    .navbarLeft {
        /* border: 1px red solid; */
        display: flex;
        width: 8rem;
    }

    .profile > button > .button1 {
        display: none;
    }

    .profile > button > .button2 {
        display: block;
    }

    .sectiontab {
        display: none;
    }
}
