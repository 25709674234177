.wrapper {
    margin-top: 1rem;
}

.wrapper > div:nth-child(1) {
    border-bottom: 1px var(--light-grey) solid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 0 1rem 0;
}

.wrapper > :nth-child(1) > p {
    font-size: 0.9rem;
}

.wrapper > div:nth-child(2) {
    /* border: 1px red solid; */
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper > div:nth-child(3) {
    margin-top: 2rem;
    display: none;
    flex-direction: row;
    gap: 0;
    justify-content: center;
}

@media (max-width: 430px) {
    .wrapper > div:nth-child(1) {
        border-bottom: 1px var(--light-grey) solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 0.2rem 0;
    }

    .wrapper > div:nth-child(1) > p:nth-child(1) {
        color: var(--grey);
        font-weight: 500;
    }

    .wrapper > div:nth-child(1) > div:nth-child(2) {
        display: none;
    }

    .wrapper > div:nth-child(2) {
        /* border: 1px red solid; */
        margin: 2rem 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
    }

    .wrapper > div:nth-child(3) {
        display: flex;
    }
}
