.phoneInput {
    border: 1px solid #d9d9d9;
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0rem;
}

.phoneInput > input,
.invalid > input {
    border: none;
    outline: none;
    width: 100%;
    margin: 0;
    padding: 0.1rem 0 0 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
}

.invalid {
    border: 1px solid #d9d9d9;
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0rem;
}
