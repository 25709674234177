.wrapper {
    /* border: 1px red solid; */
    margin-right: 0.5rem;
    background-color: var(--background);
    box-shadow: 0rem 0rem 0.5rem 0.1rem rgb(249, 244, 244);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.wrapper > div:nth-child(1) {
    /* border: 1px red solid; */
    padding: 0.4rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.wrapper > div:nth-child(1) > .mainImage {
    /* border: 1px blue solid; */
    background-color: var(--background);
    border-radius: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
}

.wrapper > div:nth-child(1) > div:nth-child(1) > img {
    /* border: 1px red solid; */
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
}

.wrapper > div:nth-child(1) > .mainImageEmpty {
    background-color: aliceblue;
    border-radius: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
}

.wrapper > div:nth-child(1) > div:nth-child(2) {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 0.2rem;
}

.wrapper > div:nth-child(1) > div:nth-child(2) > p:nth-child(1) {
    /* border: 1px red solid; */
    font-size: 0.9rem;
    font-weight: 600;
}

.wrapper > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    color: grey;
}

.wrapper > div:nth-child(2) {
    margin: 0 0.5rem;
    height: 5rem;
    position: relative;
}

.iconStyle {
    font-size: 0.9rem;
    color: var(--primary);
    cursor: pointer;
}

.iconStyle:hover {
    color: orangered;
}

.edit {
    display: none;
}

.iconStyle:hover + .edit {
    box-shadow: 0rem 0rem 0.5rem 0.1rem rgb(239, 239, 239);
    border: 1px rgb(239, 239, 239) solid;
    background-color: rgb(255, 252, 245);
    border-radius: 0.3rem;
    padding: 0.1rem 0.4rem;
    display: block;
    font-size: 0.9rem;
    color: rgb(159, 159, 159);
    position: absolute;
    top: 1.3rem;
    left: -0.6rem;
    font-size: 0.8rem;
}

@media (max-width: 430px) {
    .wrapper > div:nth-child(1) > .mainImage {
        /* border: 1px blue solid; */
        width: 10rem;
        height: 10rem;
    }

    .wrapper > div:nth-child(1) > .mainImageEmpty {
        width: 10rem;
        height: 10rem;
    }

    /* .wrapper > div:nth-child(1) > div:nth-child(2) {
        border: 1px red solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.2rem;
    } */

    .wrapper > div:nth-child(1) > div:nth-child(2) > p:nth-child(1) {
        /* border: 1px red solid; */
        font-size: 1.3rem;
        font-weight: 600;
    }

    .wrapper > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) {
        /* border: 1px red solid; */
        display: flex;
        flex-direction: column;
        gap: 0rem;
        color: var(--grey);
    }
}
