.wrapper > div:nth-child(1) {
    color: var(--primary);
    font-weight: 500;
    font-size: 1.2rem;
}

.wrapper > div:nth-child(2) {
    color: var(--blue);
    font-weight: 500;
    font-size: 1rem;
    margin: 1rem 0 0.5rem 0;
}

.noRfq {
    font-size: 0.9rem;
    color: var(--grey);
}

.rfq {
    box-shadow: 1px 2px 5px 3px rgba(142, 142, 142, 0.204);
    border-radius: 0.3rem;
    padding: 1.5rem 1rem 1rem 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    color: rgb(38, 38, 38);
    position: relative;
    overflow: hidden;
}

.sl {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary);
    color: white;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    font-size: 0.8rem;
    overflow: hidden;
    border-radius: 0 0 0.3rem 0;
}

.rfq > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
}

.gridWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

span {
    font-weight: 500;
    gap: 0;
}

@media (max-width: 430px) {
    .gridWrap {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}
