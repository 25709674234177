.btnCancel {
    border: 1px var(--text-light) solid;
    border-radius: 0.2rem;
    padding: 0.2rem 0.5rem;
    background-color: var(--background);
    color: var(--text-light);
    width: 5rem;
    cursor: pointer;
}

.btnUpdate {
    border: 1px var(--primary) solid;
    border-radius: 0.2rem;
    padding: 0.2rem 0.5rem;
    background-color: var(--primary);
    margin-left: 0.5rem;
    color: var(--background);
    width: 5rem;
    cursor: pointer;
}

.btnCancel:hover {
    border: 1px var(--button-cancel) solid;
    color: var(--button-cancel);
}

.btnUpdate:hover {
    background-color: var(--button-hover);
}
