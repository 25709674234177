.layout {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: row;
    background-color: var(--secondary);
    position: relative;
}

.layout > div:nth-child(1) {
    /* border: 1px blue solid; */
    width: 15%;
}

.layout > div:nth-child(2) {
    /* border: 1px blue solid; */
    width: 85%;
}

.layout > div:nth-child(2) > div:nth-child(2) {
    display: none;
}

.support {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    gap: 0.2rem;
    color: var(--blue);
}

.support > div {
    box-shadow: 0.03rem 0.1rem 0.5rem 0.01rem rgb(191, 191, 191);
    /* background-color: rgb(56, 56, 71); */
    background-color: var(--blue);
    color: white;
    border-radius: 50%;
    padding: 0.6rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.support > div:hover {
    /* background-color: rgb(78, 78, 99); */
    background-color: rgb(87, 87, 168);
    cursor: pointer;
}

.iconSupport {
    font-size: 1.5rem;
}

.supportDetails {
    position: fixed;
    bottom: 6.8rem;
    right: 2rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0.03rem 0.01rem 0.5rem 0.01rem rgb(208, 208, 208);
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    padding: 1rem 1rem 0 1rem;
    cursor: default;
    transition: 0.3s ease;
}

.supportDetails > div:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--primary);
}

.supportDetails > div:nth-child(2) {
    font-size: 0.9rem;
    color: rgb(45, 45, 45);
}

.supportDetails > div:nth-child(3) {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: rgb(45, 45, 45);
}

.supportDetails > div:nth-child(4) {
    border-top: 1px lightgrey solid;
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    font-size: 0.8rem;
    /* margin-bottom: 1rem; */
}

.supportDetails > div:nth-child(4) > div > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    color: var(--blue);
}

.iconSocial {
    /* color: rgb(56, 56, 71); */
    color: var(--blue);
    font-size: 1.5rem;
}

@media (max-width: 430px) {
    .layout {
        flex-direction: column;
    }

    .layout > div:nth-child(1) {
        display: none;
    }

    .layout > div:nth-child(2) {
        width: 100%;
    }

    .layout > div:nth-child(2) > div:nth-child(2) {
        display: flex;
        margin-bottom: 1rem;
        box-shadow: 0.03rem 0.01rem 0.5rem 0.01rem rgb(242, 232, 232);
        border-top: 5px var(--secondary) solid;
    }
}
