.wrapper {
    /* border: 1px blue solid; */
    gap: 0.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper > p {
    /* border: 1px red solid; */
    font-size: 0.8rem;
    color: var(--grey);
}

.wrapper > .wrapperImage {
    background-color: transparent;
    border: 1px var(--light-grey) solid;
    border-radius: 0.2rem;
    width: 12rem;
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.wrapper > .wrapperImage > p {
    /* border: 1px red solid; */
    font-size: 0.6rem;
    color: var(--grey);
}

.wrapper > .wrapperImage > img {
    /* border: 1px var(--light-grey) solid; */
    border-radius: 0.2rem;
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
}

.iconStyle {
    font-size: 2rem;
    color: var(--primary);
    margin-bottom: 0.5rem;
}

.wrapper > .wrapperImageSmall {
    background-color: transparent;
    border: 1px var(--light-grey) solid;
    border-radius: 0.2rem;
    width: 8rem;
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.wrapper > .wrapperImageSmall > p {
    /* border: 1px red solid; */
    font-size: 0.4rem;
    color: var(--grey);
}

.wrapper > .wrapperImageSmall > img {
    /* border: 1px var(--light-grey) solid; */
    /* border-radius: 0.2rem; */
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
}

.wrapper > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper > div:nth-child(3) > div:nth-child(1) {
    width: fit-content;
    font-size: 0.7rem;
    color: var(--blue);
    margin-bottom: 1rem;
}

.wrapper > div:nth-child(3) > div:nth-child(2) {
    width: fit-content;
    font-size: 0.8rem;
    background-color: rgb(234, 234, 234);
    padding: 0.1rem 0.5rem;
    border-radius: 0.2rem;
    color: var(--button-cancel);
    cursor: pointer;
}
